import type { MouseEvent as ReactMouseEvent } from 'react';

import React, { useEffect, useRef, useState } from 'react';
import {
  Text,
  TEXT_PROPS,
} from '@user-interviews/ui-design-system';
import { useTracking } from 'react-tracking';

import { trackingEvents } from 'lib/analytics';

import { useAuthUserContext } from 'hooks/use_auth_user_context';

import { useSidebarMobileBreakpoint } from 'hooks/use_sidebar_mobile_breakpoint';
import { useSidebarContext } from '../../hooks/use_sidebar_context';
import { AccountMenuPopover } from './components/account_menu_popover';

import * as styles from './account_navigation_item.module.scss';

const { NAVIGATION_ACCOUNT_POPOVER_OPENED } = trackingEvents;

export function AccountNavigationItem() {
  const { trackEvent } = useTracking();
  const ref = useRef<HTMLDivElement>(null);
  const { isRenderingOpen, setIsNavItemHovered } = useSidebarContext();
  const isMobile = useSidebarMobileBreakpoint();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const authUserContext = useAuthUserContext();

  useEffect(() => {
    if (isRenderingOpen) return;

    setIsAccountMenuOpen(false);
  }, [isRenderingOpen]);

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent) {
      if (!ref.current) return;
      const { clientX, clientY } = event;
      const {
        top, bottom, left, right,
      } = ref.current.getBoundingClientRect();

      if (
        clientX >= left &&
        clientX <= right &&
        clientY >= top &&
        clientY <= bottom
      ) return;

      setIsAccountMenuOpen(false);
    }

    function addEventListener() {
      window.addEventListener('click', handleDocumentClick);
    }

    function removeEventListener() {
      window.removeEventListener('click', handleDocumentClick);
    }

    addEventListener();

    return removeEventListener;
  }, []);

  function handlePopoverButtonClick(event: ReactMouseEvent) {
    event.stopPropagation();
    setIsAccountMenuOpen(prev => !prev);
  }

  function handleMouseEnter() {
    setIsNavItemHovered(true);
  }

  function handleMouseLeave() {
    setIsNavItemHovered(false);
  }

  useEffect(() => {
    if (!isAccountMenuOpen) return;

    trackEvent({ event: NAVIGATION_ACCOUNT_POPOVER_OPENED });
  }, [isAccountMenuOpen, trackEvent]);

  if (!authUserContext?.account) return null;

  const { account, organization } = authUserContext;
  const isRenderingAccountMenu = isAccountMenuOpen && (isRenderingOpen || isMobile);

  return (
    <div className={styles.accountSettingsNavigationItemContainer}>
      <button
        aria-label="open account menu"
        className={styles.accountSettingsNavigationItem}
        type="button"
        onClick={handlePopoverButtonClick}
        onFocus={handleMouseEnter}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.avatar}>
          <span>{account.initials}</span>
        </div>

        {isRenderingOpen && (
          <div className={styles.accountDetails}>
            <Text className={styles.boldedText} size={TEXT_PROPS.size.sm}>{account.fullName}</Text>
            {organization && <Text className={styles.text} size={TEXT_PROPS.size.sm}>{organization.name}</Text>}
          </div>
        )}
      </button>
      {isRenderingAccountMenu && <AccountMenuPopover ref={ref} />}
    </div>
  );
}
