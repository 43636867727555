import { useTracking } from 'react-tracking';
import { reactTrackingEvent } from 'lib/analytics';
import type { TrackingHook } from 'react-tracking';

// Force the Track component to be synchronous
export type SyncTrackingHook = Omit<TrackingHook, 'Track'> & {
  Track: React.ComponentType<{ children?: React.ReactNode }>;
};

export const useTrackingWithDefaultDispatch = (trackingData = {}): SyncTrackingHook => (
  useTracking(
    trackingData,
    { dispatch: reactTrackingEvent },
  ) as SyncTrackingHook
);
