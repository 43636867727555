import {
  useContext, useMemo,
} from 'react';
import AuthUserContext from 'common/authorization/auth_user_context';

import { MATCH_TYPES } from 'common/authorization/match_types';

const MATCH_HANDLERS = {
  [MATCH_TYPES.ANY]: permissions => permissions.some.bind(permissions),
  [MATCH_TYPES.ALL]: permissions => permissions.every.bind(permissions),
};

const checkAuthorization = (config, authorization) => {
  const { matchType = MATCH_TYPES.ALL, to, notTo } = config;
  const hasAllowlist = to && to.length > 0;
  const hasBlocklist = notTo && notTo.length > 0;
  if (!hasAllowlist && !hasBlocklist) {
    return false;
  }

  const authorize = (permissions, target) => {
    const permissionsArray = [].concat(permissions);
    const checkPermissions = MATCH_HANDLERS[matchType](permissionsArray);
    return checkPermissions(permission => (authorization[permission] === true) === target);
  };

  const authorizationList = [];
  if (hasAllowlist) {
    authorizationList.push(authorize(to, true));
  }
  if (hasBlocklist) {
    authorizationList.push(authorize(notTo, false));
  }

  return authorizationList.every(auth => auth === true);
};

const useAuthorization = (config) => {
  const { matchType = MATCH_TYPES.ALL } = config;
  if (MATCH_HANDLERS[matchType] === undefined) {
    throw new Error(`matchType must be one of: ${Object.keys(MATCH_HANDLERS)}`);
  }

  const context = useContext(AuthUserContext);
  if (!context) {
    throw new Error('useAuthorization invoked without AuthUserContext');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const authorization = context.authorization || {};

  return useMemo(() => checkAuthorization(config, authorization), [config, authorization]);
};

export default useAuthorization;
