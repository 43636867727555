import React, { createContext, useContext, useMemo } from 'react';
import propTypes from 'prop-types';

import { Toast, useToast, MessageTypes } from '@user-interviews/ui-design-system';
import { useTracking } from 'react-tracking';
import { connectedCalendarDetailsPropType } from 'components/connect_calendar/prop_types';
import { useAuthUserContext } from 'hooks/use_auth_user_context';
import { type SyncTrackingHook } from 'hooks/use_tracking_with_default_dispatch';

type ProjectWorkspaceProviderProps = {
  children: React.ReactNode;

  projectId: string;
  connectedCalendarDetails?: any;
  serverProject?: {
    isDraft: boolean;
    isPrivate: boolean | null;
    isUnmoderatedTask: boolean | null;
    isFunnelStagesEnabled: boolean;
  };
};

type ProjectWorkspaceContextType = Omit<ProjectWorkspaceProviderProps, 'children'> & {
  setToastMessage: (options: {
    title?: string;
    type: typeof MessageTypes[keyof typeof MessageTypes];
    message: string;
  }) => void;
}

const ProjectWorkspaceContext = createContext<ProjectWorkspaceContextType>({} as ProjectWorkspaceContextType);

export function ProjectWorkspaceProvider({
  children,
  connectedCalendarDetails,
  projectId,
  serverProject,
}: ProjectWorkspaceProviderProps) {
  const authUserContext = useAuthUserContext();
  const { dismissMessage, messages, setMessage } = useToast();

  const { Track } = useTracking({
    eventData: {
      project_id: projectId,
      ...(authUserContext?.account && { account_id: authUserContext.account.id }),
      ...(authUserContext?.user && { user_id: authUserContext.user.id }),
    },
  }) as SyncTrackingHook;

  const contextValue = useMemo(() => ({
    connectedCalendarDetails,
    projectId,
    serverProject,
    setToastMessage: setMessage,
  }), [connectedCalendarDetails, projectId, serverProject, setMessage]);

  return (
    <ProjectWorkspaceContext.Provider value={contextValue}>
      <Toast autoDismiss messages={messages} onToastClosed={dismissMessage} />
      <Track>
        {children}
      </Track>
    </ProjectWorkspaceContext.Provider>
  );
}

ProjectWorkspaceProvider.propTypes = {
  connectedCalendarDetails: connectedCalendarDetailsPropType,
  projectId: propTypes.string.isRequired,
  serverProject: propTypes.object,
};

export function useProjectWorkspaceContext() {
  return useContext(ProjectWorkspaceContext);
}
