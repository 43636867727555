import type { ReactElement } from 'react';

import React from 'react';
import { BrowserRouter, StaticRouter } from 'react-router';
import { isServer } from 'lib/ssr_utils';

export function DopeBrowserRouter({ location, children }: { location: string, children: ReactElement }) {
  if (isServer) {
    return (
      <StaticRouter location={location}>
        {children}
      </StaticRouter>
    )
  }

  return (
    <BrowserRouter>
      {children}
    </BrowserRouter>
  );
}
