import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  type RefObject,
  type ReactElement,
} from 'react';

import { useIsSidebarOpen } from '../sidebar/hooks/use_is_sidebar_open';

export const SidebarContext = createContext<{
  isOpen: boolean,
  isTransitioning: boolean,
  isRenderingOpen: boolean,
  setIsNavItemHovered: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isOpen: false,
  isTransitioning: false,
  isRenderingOpen: false,
  setIsNavItemHovered: () => { },
});

export function SidebarContextProvider({ sidebarRef, children }: {
  sidebarRef: RefObject<HTMLElement | null>,
  children: ReactElement,
}) {
  const [isNavItemHovered, setIsNavItemHovered] = useState(false);
  const [isSidebarHovered, setIsSidebarHovered] = useState(false);
  const { isOpen, isTransitioning } = useIsSidebarOpen({ sidebarRef, isNavItemHovered, isSidebarHovered });

  useEffect(() => {
    if (!sidebarRef.current) return undefined;
    const { current: sidebar } = sidebarRef;

    function handleMouseEnter() {
      setIsSidebarHovered(true);
    }

    function handleMouseLeave() {
      setIsSidebarHovered(false);
      setIsNavItemHovered(false);
    }

    sidebar.addEventListener('mouseenter', handleMouseEnter);
    sidebar.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      sidebar.removeEventListener('mouseenter', handleMouseEnter);
      sidebar.removeEventListener('mouseleave', handleMouseLeave);
    }
  }, [sidebarRef]);

  const contextValue = useMemo(() => ({
    isOpen,
    isTransitioning,
    isRenderingOpen: isOpen || isTransitioning,
    setIsNavItemHovered,
  }), [isTransitioning, isOpen]);

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
}
